<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-03-10 17:57:54
     */
    import ltreemenu from './ltree_menu.vue'

    export default {
        components: {ltreemenu},
        name: 'ltreeitem',
        props: {
            w: {
                type: String,
                default: '400px'
            },
            // 点击回馈
            feedback: {
                type: Function,
                default: () => {
                }
            },
            // 菜单按钮
            menu: {
                type: Array,
                default: []
            },
            // 现在激活的列表
            active: {
                type: Array,
                default: []
            },
        },
        methods: {
            itemTitleClick() {
                this.feedback([{id: 'all'}])
            }
        }
    }
</script>
<template>
    <div>
        <div>
            <div class="group_wrapper">
                <div class="group_title_wrapper" :class="(active.length && active[active.length - 1] === 'all')?'group_active':''">
                    <div class="group_bg_wrapper" :style="{
            width:w
          }"></div>
                    <div class="more_icon_wrapper">
                        <div class="menu_wrapper">
                            <ltreemenu :menu="menu" :info="{id:'all'}"></ltreemenu>
                        </div>
                        <div class="czs-more"></div>
                    </div>
                    <div class="group_title_wrapper" @click="itemTitleClick()">
                        <a-space>
                            <!--                                          <div class="dir_open_icon_wrapper">-->
                            <!--                                            <div class="dir_open">-->
                            <!--                                              <i class="czs-triangle-o"></i>-->
                            <!--                                            </div>-->
                            <!--                                          </div>-->
                            <div class="item_icon_wrapper">
                                <a-icon type="folder" theme="filled" style="vertical-align: middle; font-size: 14px; color: #666"/>
                            </div>
                            <div class="group_name_wrapper">全部</div>
                        </a-space>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .dir_open_icon_wrapper{
        opacity: 0;
        }

    .menu_wrapper{
        display: none;
        }

    .more_icon_wrapper:hover .menu_wrapper{
        display: block;
        }



    .more_icon_wrapper .czs-more{
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 20px;
        transform: rotate(90deg) translateX(-50%);
        z-index: 1;
        display: none;
        z-index: 99999;
        }


    .group_active .group_bg_wrapper{
        position: absolute;
        height: 40px;
        right: 0;
        background: #c9e3ff;
        z-index: -1;
        }

    .group_title_wrapper:hover .more_icon_wrapper .czs-more{
        display: block;
        }

    .group_title_wrapper{
        position: relative;
        cursor: pointer;
        height: 40px;
        }

    .group_name_wrapper{
        user-select: none;
        }

    .item_icon_wrapper{
        font-size: 16px;
        }

    .dir_open_icon_wrapper{
        font-size: 12px;
        }

    .group_wrapper{
        min-height: 40px;
        line-height: 40px;
        }

    .dir_close{
        transform: rotate(90deg);
        }

    .dir_open{
        transform: rotate(180deg);
        }

</style>
