<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-03-10 17:25:20
     */
    import ltreeitem from './ltree_item.vue'
    import ltreeall from './ltree_all.vue'

    export default {
        components: {ltreeitem, ltreeall},
        props: {
            w: {
                type: String,
                default: '400px'
            },
            // 是否有全部
            all: {
                type: String,
                default: ''
            },
            // 数据
            data: {
                type: Array,
                default: []
            },
            // 菜单按钮
            menu: {
                type: Array,
                default: []
            },
            // 菜单按钮
            all_menu: {
                type: Array,
                default: []
            },
            // 文件夹类型
            dt: {
                type: Number,
                default: 0
            },
            // 内容类型
            it: {
                type: Number,
                default: 1
            },
            // 打开类型 all-不限制 only-只能打开一个
            ot: {
                type: String,
                default: 'all'
            },
            // 打开类型 all-不限制 only-只能打开一个
            callback: {
                type: Function,
                default: () => {
                }
            },
            renameDo: {
                type: Function,
                default: () => {
                }
            }
        },
        data() {
            return {
                active_arr: [],
                rename_active: '',
            }
        },
        mounted() {
        },
        methods: {
            renameActive(id) {
                this.rename_active = id
            },
            openGroup(arr) {
                this.active_arr = arr
            },
            allClick() {
                this.active_arr = ['l_all']
            },
            feedback(arr) {
                this.active_arr = arr.map((item) => {
                    return item.id
                })
                this.callback(arr)
            }
        }
    }
</script>
<template>
    <div>
        <div :style="{width:w}" class="ltree_wrapper">
            <ltreeall v-if="all === 'all'" :w="w" :active="active_arr" :menu="all_menu" :feedback="feedback"></ltreeall>
            <ltreeitem :ot="ot" :w="w" :dt="dt" :menu="menu" :rename_active="rename_active" :active="active_arr" :it="it" :info="gi" v-for="(gi,gk) in data" :renameDo="renameDo" :feedback="feedback"></ltreeitem>
        </div>
    </div>
</template>
<style>
    *{
        /*background: #00000010;*/
        }
</style>
<style scoped>
    .all_active{
        background: #c9e3ff;
        }

    .group_name_wrapper{
        user-select: none;
        }

    .item_icon_wrapper{
        font-size: 16px;
        }

    .dir_open_icon_wrapper{
        font-size: 12px;
        opacity: 0;
        }

    .all_wrapper{
        height: 40px;
        line-height: 40px;
        }

    .ltree_wrapper{
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        padding-left: 10px;
        padding-bottom: 30px;
      }

    .ltree_wrapper::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 0px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }
    .ltree_wrapper::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius:0px;
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        background   : #535353;
        }
    .ltree_wrapper::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background   : #ededed;
        }







    .group_name_wrapper{
        width: 100%; overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }


</style>
